define(['$window', 'app', 'siteObj'], ($window, app, siteObj) => {

  const responsivePagination = () => {
    var component = {};

    const _config = {
      selectors: {
        responsivePagination: '[data-responsive-pagination]',
        pageSelectorNumber: '[data-page-number]',
        pageActiveSelector: '[data-page-active]',
        nextPageButtonSelector: '[data-direction="next"]',
        prevPageButtonSelector: '[data-direction="previous"]',
      },
      attributes: {
        pageSelectorNumber: 'data-page-number',
      }
    };

    const _init = (element) => {
      component.element = element;

      component.responsivePagination = [...component.element.querySelectorAll(component.config.selectors.responsivePagination)];

      component.pageSelectorElems = [...component.element.querySelectorAll(component.config.selectors.pageSelectorNumber)];
      component.nextPageButtonElems = [...component.element.querySelectorAll(component.config.selectors.nextPageButtonSelector)];
      component.prevPageButtonElems = [...component.element.querySelectorAll(component.config.selectors.prevPageButtonSelector)];

      component.bind();

      return component;
    };

    const _bind = () => {
      component.pageSelectorElems.map(el => el.addEventListener('click', component.pageSelectorClicked));
      component.nextPageButtonElems.map(el => el.addEventListener('click', component.nextPageButtonClicked));
      component.prevPageButtonElems.map(el => el.addEventListener('click', component.prevPageButtonClicked));

      component.updateAiraLabel();
    };

    const _pageSelectorClicked = (event) => {
      event.preventDefault();
      let elem = event.target;
      let pageNumber = component.getPageNumberOf(elem);
      component.updatePageNumber(pageNumber);
    };

    const _nextPageButtonClicked = () => {
      let currentPageElem = component.element.querySelector(component.config.selectors.pageActiveSelector);
      let currentPageNumber = component.getPageNumberOf(currentPageElem);
      component.updatePageNumber(parseInt(currentPageNumber) + 1);
    };

    const _prevPageButtonClicked = () => {
      let currentPageElem = component.element.querySelector(component.config.selectors.pageActiveSelector);
      let currentPageNumber = component.getPageNumberOf(currentPageElem);
      component.updatePageNumber(parseInt(currentPageNumber) - 1);
    };

    const _getPageNumberOf = (pageNumberElem) => {
      return pageNumberElem.getAttribute(component.config.attributes.pageSelectorNumber);
    };

    const _updatePageNumber = (value) => {
      app.publish('responsiveProductListPage/update', { pageNumber: value });
    };

    const _updateAiraLabel  = () => {

      var pagination = component.responsivePagination;

      if ( pagination.length > 1 ) {

        pagination[0].setAttribute("aria-label", siteObj.props.accessibility.paginationLabelNameTop);
        pagination[1].setAttribute("aria-label", siteObj.props.accessibility.paginationLabelNameBottom);  
      }

      return component;

    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.pageSelectorClicked = _pageSelectorClicked;
    component.nextPageButtonClicked = _nextPageButtonClicked;
    component.prevPageButtonClicked = _prevPageButtonClicked;
    component.getPageNumberOf = _getPageNumberOf;
    component.updatePageNumber = _updatePageNumber;
    component.updateAiraLabel =  _updateAiraLabel;

    return component;
  };

  return responsivePagination;
});
